body{
    margin: 0;
    padding: 0;

    font-family: var(--font-family);

  }
  .team-section{
    background: var(--color-footer);

    /* ff 3.6+ */
    background:-moz-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4144A3', endColorstr='#8D3198', GradientType=0 );

    /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    text-align: center;
  }
  .inner-width{
    max-width: 1200px;
    margin: auto;
    padding: 40px;
    color: #fff;
    overflow: hidden;
  }
  .team-section h1{
    font-family: var(--font-family);
    font-size: 20px;
    color: #fff;
    text-shadow: 0 0 14px rgb(222, 28, 138);
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 4px solid;
    padding-bottom: 10px;
  }
  .pers{
    width: 100%;
    height: 100%;
  }
  .pe{
    float: left;
    width: calc(100% / 4);
    height: 320px;
    overflow: hidden;
    padding: 40px 0;
    border-radius: 10px;
    transition: 0.4s;
  }
  .pe:hover{
    background: #ddd;
    transform: scale(1.15);

  }
  .pe img{
    border-radius: 10px;
    width: 120px;
    height: 120px;
  }
  .p-name{
    margin: 16px 0;
    text-transform: uppercase;
  }
  .p-des{
    font-style: italic;
    color: #81AFDD;
  }
  .p-sm{
    margin-top: 12px;
  }
  .p-sm a{
    margin: 0 4px;
    display: inline-block;
    width: 30px;
    height: 30px;
    transition: 0.4s;
  }
  .p-sm a:hover{
    transform: scale(1.3);
  }
  .p-sm a i{
    color: #333;
    line-height: 30px;
  }
  @media screen and (max-width:600px) {
    .pe{
      width: 100%;
    }
  }

.row {
  display: flex;
  border: 1px solid orange;
  flex-wrap: wrap;
  padding: 2em 1em;
  text-align: center;
}

.column {
  border: 1px solid black;
  width: 100%;
  padding: 0.5em 0;
}

.card {
  box-shadow: 0 0 0.2em rgba(25, 0, 58, 0.1);
  padding: 3.5em 1em;
  border-radius: 0.6em;
  color: #1f003b;
  cursor: pointer;
  transition: 0.3s;
  background-color: #fff;
}

.card .img-container {
  /* width:  8em;
  height: 8em; */
  width:  40%;
  height: 40%;
  background-color: #a993ff;
  padding: 0.5em;
  border-radius: 10px;
  margin: 0 auto 2em auto;
}

.card img {
  width: 100%;
  border-radius: 10px;
}

.card h3 {
  font-weight: 500;
}

.card p {
  font-weight: 300;
  text-transform: uppercase;
  margin: 0.5em 0 2em 0;
  letter-spacing: 2px;
}

.card:hover {
  background: linear-gradient(#6045ea, #8567f7);
  color: #ffffff;
}

.card:hover .img-container {
  transform: scale(1.15);
}

@media screen and (min-width: 768px) {
  section {
    padding: 1em 7em;
  }
}

@media screen and (min-width: 992px) {
  section {
    padding: 1em;
  }
  .card {
    padding: 5em 1em;
  }
  .column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0 1em;
  }
}

.small {
  width: 40%;
  float: left;
  background: lightblue;
  margin: 2%;
  padding: 10px;
  box-sizing: border-box;
}

.bio {
  margin: 16px 0;
  color: #fff;
}

.bio-title {
  font-family: var(--font-family);
  font-size: 20px;
  color: #fff;
  text-shadow: 0 0 14px rgb(222, 28, 138);
  text-transform: uppercase;
  display: inline-block;
  border-bottom: 4px solid;
  padding-bottom: 10px;
}

.bio-img {
  border-radius: 10px;
  width: 25%;
  height: 25%;
}

.bio-button {
  font-family: var(--font-family);
  font-weight: 300;
  background: #FF4820;
  color: #fff;
  outline: none;
  border: 5px;
  padding: 1rem 2rem;
  border-radius: 20px;
  cursor: pointer;
}


@media screen and (max-width: 650px) {
  .bio {
    font-size: 14px;
  }
  .bio-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 490px) {
  .bio {
    font-size: 12px;
  }
  .bio-title {
    font-size: 14px;
    text-transform: lowercase;

  }
}