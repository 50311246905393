.swiitverse__possibility {
  display: flex;
  flex-direction: row;
}

.swiitverse__possibility-image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-right: 2rem;
}

.swiitverse__possibility-image img {
  width: 100%;
  height: 100%;
}

.swiitverse__possibility-image video {
  width: 100%;
  height: 100%;
}

.swiitverse__possibility-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0;
}

.swiitverse__possibility-content h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #71E5FF;
}

.swiitverse__possibility-content h4:last-child {
  color: #ff8A71;
}

.swiitverse__possibility-content h1 {
  font-family: var(--font-family);
  color: #fff;
  text-shadow: 0 0 14px rgb(222, 28, 138);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem 0;
}

.swiitverse__possibility-content img {
  max-height: 30px;
  line-height: 30px;
}

.swiitverse__possibility-content p {
  color: var(--color-text);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;

  margin-bottom: 2rem;
}

.swiitverse__possibility-textfoot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  padding: 2rem;
  margin: 2rem;
  border-radius: 1rem;
  background: var(--gradient-bar)
}

.swiitverse__possibility-textfoot h2{
  color: #fff;
}

.swiitverse__possibility-video{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.swiitverse__possibility-buttonNumber{
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #FF4820;
  border: 2px solid #FF4820;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}


.containerInput {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  
  width: 100%;
}

.containerInput p{
  font-family: var(--font-family);
  color: #fff;
  text-shadow: 0 0 14px rgb(222, 28, 138);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem;
  padding: 1rem;
}

.buttonNumber {
  flex: 0.6;
  width: 100%;
  max-width: fit-content;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #FF4820;
  border: 2px solid #FF4820;
  padding: 1 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 20px;
}

.buttonMint {
  flex: 0.6;
  width: 100%;
  max-width: fit-content;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #FF4820;
  border: 2px solid #FF4820;
  padding: 1 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 20px;
}

@media screen and (max-width: 950px) {
  .swiitverse__possibility {
      flex-direction: column;
  }

  .swiitverse__possibility-image {
      margin: 1rem 0;
  }

  .swiitverse__possibility-image img {
      width: unset;
      height: unset;
  }

  .swiitverse__possibility-content {
      margin-top: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .swiitverse__possibility-image img {
      width: 100%;
      height: 100%;
  }
}

