.swiitverse__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

/*     background: var(--color-footer);
 */    background:-moz-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4144A3', endColorstr='#8D3198', GradientType=0 );

    /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.swiitverse__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.swiitverse__footer-heading h1 {
    color: #fff;
    text-shadow: 0 0 14px rgb(222, 28, 138);
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.swiitverse__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;

    margin-bottom: 10rem;
    cursor: pointer;
}

.swiitverse__footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
}

.swiitverse__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.swiitverse__footer-links div {
    width: 250px;
    margin: 1rem;
}

.swiitverse__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.swiitverse__footer-links_logo img {
    width: 200px;
    height: 85px;

    margin-bottom: 1rem;
}

.swiitverse__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.swiitverse__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.swiitverse__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}

.swiitverse__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.swiitverse__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.swiitverse__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .swiitverse__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .swiitverse__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .swiitverse__footer-links div {
        margin: 1rem 0;
    }

    .swiitverse__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .swiitverse__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}
