

.section__padding {
    padding: 4rem 6rem;
}
  
  .section__margin {
    margin: 4rem 6rem;
}
  
  .scale-up-center {
      -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.swiitverse__whitelist {
  display: flex;
  flex-direction: row;

  margin: 2rem auto;
  padding: 2rem;
  width: 100%;
  max-width: 50%;

/* ff 3.6+ */
background:-moz-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%); 

/* safari 5.1+,chrome 10+ */
background:-webkit-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

/* opera 11.10+ */
background:-o-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

/* ie 6-9 */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4144A3', endColorstr='#8D3198', GradientType=0 );

/* ie 10+ */
background:-ms-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

/* global 94%+ browsers support */
background:linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.swiitverse__whitelist-form {
  width: 100%;
}

.swiitverse__whitelist-form h1{
  width: 100%;
  color: #fff;
  text-align: center;
}

.swiitverse__whitelist-formEnd {
  width: 100%;
  margin: 2rem 0 1rem;
  position:relative; 
}
.swiitverse__whitelist-formEnd h1{
  width: 100%;
  margin: 2rem 0 1rem;
  color: #fff;
  text-align: center;
}
.swiitverse__whitelist-formEnd h4{
  width: 100%;
  color: #fff;
  margin: 2rem 0 1rem;
  text-align: center;
}
.swiitverse__whitelist-formEnd img{
  width: 100%;
  margin: 2rem 0 1rem;
  text-align: center;
  cursor: pointer;
}

.swiitverse__whitelist-form form {
}

.swiitverse__whitelist-form div {
  width:auto;
  text-align: center;
  margin: .5rem;

  vertical-align: middle;
  font-family: var(--font-family);
  color: #fff;
  font-size: 20px;
}

.swiitverse__whitelist-form div input {
  width: auto;
}

.swiitverse__whitelist-form label {
  text-align: left;
  font-family: var(--font-family);
  color: #fff;
  font-size: 20px;
  margin-right: 0px;
  margin-left: 0px;
  width: 100%;
}

.swiitverse__whitelist-form input {
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;
  margin: .5rem;
  border-radius: 5px;
  margin-right: 0px;
  margin-left: 0px;
  width: 100%;
}

.swiitverse__whitelist-form textarea {
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;
  margin: .5rem;
  border-radius: 5px;
  margin-right: 0px;
  margin-left: 0px;
  width: 100%; 
}

.swiitverse__whitelist-form button {
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #FF4820;
  border: 2px solid #FF4820;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;

}

.swiitverse__whitelist-form p {
  color: #fff;
  font-size: 16px;
}

@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}
  
@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

@media screen and (max-width: 700px) {
  .swiitverse__whitelist {
    max-width: 90%;
  }

    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
@media screen and (max-width: 550px) {
  .swiitverse__whitelist {
    max-width: 90%;
  }
  
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
}



.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

html {
  font-size: 16px;
}

body {
  font-family: 'Roboto', sans-serif;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.banniere {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.banniere img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.count {
  font-size: 2rem;
  font-weight: 700;
}

.cost {
  font-weight: 300;
}

.withdraw {
  position:absolute;
  top: 1rem;
  left: 1rem;
}