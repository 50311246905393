



.section__padding {
    padding: 4rem 6rem;
}
  
  .section__margin {
    margin: 4rem 6rem;
}
  
  .scale-up-center {
      -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.swiitverse__whitepaper {
  display: flex;

  margin: 2rem auto;
  padding: 2rem;
  width: 100%;
  max-width: 90%;

/* ff 3.6+ */
background:-moz-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%); 

/* safari 5.1+,chrome 10+ */
background:-webkit-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

/* opera 11.10+ */
background:-o-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

/* ie 6-9 */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4144A3', endColorstr='#8D3198', GradientType=0 );

/* ie 10+ */
background:-ms-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

/* global 94%+ browsers support */
background:linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.swiitverse__whitepaper-form {
    text-align: left;
    width: 100%;
}

.swiitverse__whitepaper-form h1{
    font-family: var(--font-family);
    color: #fff;
    text-shadow: 0 0 14px rgb(222, 28, 138);

    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    width: 100%;
    text-align: center;
}

.swiitverse__whitepaper-form label {
  font-family: var(--font-family);
  color: #fff;
  font-size: 20px;
  width: 100%;
  text-align: left;
}

.swiitverse__whitepaper-form img {
    max-width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
}

.tabText {
  margin-left: 25px;
}
/* 
.swiitverse__whitepaper-form div {
  width:auto;
  text-align: center;
  margin: .5rem;

  vertical-align: middle;
  font-family: var(--font-family);
  color: #fff;
  font-size: 20px;
} */

.swiitverse__whitepaper-form div input {
  width: auto;
}

.swiitverse__whitepaper-form p {
  color: #fff;
  font-size: 16px;
}

.containerDownload {
  text-align: center;

}

.buttonDownload {
  width: fit-content;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #FF4820;
  border: 2px solid #FF4820;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;

}



@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}
  
@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

@media screen and (max-width: 700px) {
  .swiitverse__whitepaper {
    max-width: 90%;
    margin: 1rem auto;
    padding: 0.5rem;
  }

  .swiitverse__whitepaper-form h1{
    font-size: 28px;
    line-height: 40px;
  }

  .swiitverse__whitepaper-form img {
    max-width: 100%;
}
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
@media screen and (max-width: 550px) {
  .swiitverse__whitepaper {
    max-width: 90%;
  }

  .swiitverse__whitepaper-form h1{
    font-size: 28px;
    line-height: 40px;
  }
  
  .swiitverse__whitepaper-form img {
    max-width: 100%;
}

    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
}