.swiitverse__WhitelistVerification {
    display: flex;
    flex-direction: row;
    background: linear-gradient(114.95deg, rgba(235, 0, 255, 0.5) 0%, rgba(0, 71, 255, 0) 34.35%), linear-gradient(180deg, #004B5B 0%, #FFA7A7 100%), linear-gradient(244.35deg, #FFB26A 0%, #3676B1 50.58%, #00A3FF 100%), linear-gradient(244.35deg, #FFFFFF 0%, #004A74 49.48%, #FF0000 100%), radial-gradient(100% 233.99% at 0% 100%, #B70000 0%, #AD00FF 100%), linear-gradient(307.27deg, #219D87 0.37%, #2650BA 50.19%, #2800C6 100%), radial-gradient(100% 140% at 100% 0%, #FF00C7 0%, #006C7A 49.48%, #760000 100%);
    background-blend-mode: hard-light, overlay, overlay, overlay, difference, difference, normal;

}

.input-textVerifyWL {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid #fff;
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-radius: 5px;
}

.buttonVerifyWL {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #ffffff;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-radius: 5px;
    margin-top: 1rem;
}

.containerCarou {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.swiitverse__WhitelistVerification p{
    color: #fff;
}

/* 
.gallery {
    position: relative;
    width: 300px;
    height: 200px;
    transform-style: preserve-3d;
    animation:rotate 15s linear infinite;
}

@keyframes rotate {
    from {
        transform:perspective(1200px)
            rotateY(0deg);
    }

    to {
        transform:perspective(1200px)
            rotateY(360deg);
    }
}

.gallery span {
    position: relative;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform:rotateY(calc(var(i) * 45deg))
        translateZ(380px);
}

.gallery span img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
} */