.swiitverse__error {
    width: 100%;
    align-items: center;
    text-align: center;
}

.swiitverse__error h1 {
    color: #fff;
    text-shadow: 0 0 14px rgb(222, 28, 138);

    letter-spacing: -0.04em;  

    font-size: 62px;
    line-height: 75px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 100%;
    padding: 2rem;
}

.swiitverse__error button {
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
}