.swiitverse__brand {
width: 100%;
}

.swiitverse__brand-title {
    justify-content: center;
    text-align: center;
}

.swiitverse__brand-title h1 {
    font-family: var(--font-family);
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    letter-spacing: -0.04em;
    color: #fff;
    text-shadow: 0 0 14px rgb(222, 28, 138);
}

.swiitverse__brand-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
}

.swiitverse__brand-logo div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}