.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position: fixed; 
        /* want to fix the height to the window height */
        height: calc(var(--window-inner-height) - 1px);

        /* want to block all overflowing content */
        overflow: hidden;
    
        /* want to exclude padding from the height */
        box-sizing: border-box;

    background: var(--color-footer);

    /* ff 3.6+ */
    background:-moz-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4144A3', endColorstr='#8D3198', GradientType=0 );

    /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    text-align: center;

    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  .bio-button {
    font-family: var(--font-family);
    font-weight: 300;
    background: #FF4820;
    color: #fff;
    outline: none;
    border: 5px;
    padding: 1rem 2rem;
    border-radius: 20px;
    cursor: pointer;
  }


  
@media screen and (max-width: 1050px) {
    .modal-main {
        width: 80%;
    }
}

@media screen and (max-width: 650px) {
    .modal-main {
        width: 80%;
        position: auto;
    }
}

@media screen and (max-width: 490px) {
    .modal-main {
        width: 80%;
        position: auto; 

    }
}

