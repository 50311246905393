.swiitverse__swiitverse {
    display: flex;
    flex-direction: row;
    


        /* ff 3.6+ */
        background:-moz-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%); 

        /* safari 5.1+,chrome 10+ */
        background:-webkit-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);
    
        /* opera 11.10+ */
        background:-o-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);
    
        /* ie 6-9 */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4144A3', endColorstr='#8D3198', GradientType=0 );
    
        /* ie 10+ */
        background:-ms-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);
    
        /* global 94%+ browsers support */
        background:linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.swiitverse__swiitverse-feature {
    padding: 2rem;
    width: 100%;
    display: block;
}

/* Customizing feature component as per needs */
.swiitverse__swiitverse-feature .swiitverse__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.swiitverse__swiitverse-feature .swiitverse__features-container_feature-text {
    max-width: 700px;
}

.swiitverse__swiitverse-title h1{
    font-family: var(--font-family);

    color: #fff;
    text-shadow: 0 0 14px rgb(222, 28, 138);

    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    max-width: 510px;
}

.swiitverse__swiitverse-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.swiitverse__swiitverse-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.swiitverse__swiitverse-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.swiitverse__swiitverse-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.swiitverse__swiitverse-container .swiitverse__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.swiitverse__swiitverse-container .swiitverse__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .swiitverse__swiitverse-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .swiitverse__swiitverse-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .swiitverse__swiitverse-feature .swiitverse__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .swiitverse__swiitverse-feature .swiitverse__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .swiitverse__swiitverse-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }

}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .swiitverse__swiitverse-container .swiitverse__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}

/* Test */
.swiitverse__swiitverse-description {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.swiitverse__swiitverse-description-title {
    flex: 1;
    margin-right: 2rem;
}

.swiitverse__swiitverse-description-title h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.swiitverse__swiitverse-description-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.swiitverse__swiitverse-description-text {
    display: flex;
}

.swiitverse__swiitverse-description-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
}
/* Fin */

@media screen and (max-width: 550px) {
    .swiitverse__swiitverse-title h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .swiitverse__swiitverse-description-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .swiitverse__swiitverse-description-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .swiitverse__swiitverse-description {
        margin: 1rem 0;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .swiitverse__swiitverse-description {
        margin: 1rem 0;
        min-width: 100%;
    }
}