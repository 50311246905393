.topBar {
    display: flex;
    margin: 2rem 6rem;
    width: 100%;
    max-width: 90%;
    height: 5rem;
    position: fixed;
    justify-content: center;
    align-content: center;
    z-index: 100;
}

.menuBar {
    display: flex;
    align-items: flex-end;
    color: white;
    justify-content: flex-end;
    width: 100%;
}

.navIcon {
    width: 55px;
    display: flex;
    margin-right: 2rem;
    margin-left: 1.5rem;
    cursor: pointer;
}

.sideMenu {
    left: 0;
    height: 100%;
    width: 260px;
    position: fixed;
    z-index: 10;
    background: rgb(249, 249, 249);
    transition: all 0.35s ease-out;
}

.sideMenu a {
    display: flex;
    align-content: flex-start;
    text-decoration: none;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    padding: 1rem 1.6rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sideMenu a:hover {
    color: rgb(40, 0, 148);
}

.sideMenu p {
    display: flex;
    align-content: flex-start;
    text-decoration: none;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    padding: 1rem 1.6rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sideMenu p:hover {
    color: rgb(40, 0, 148);
}

.iconMenu {
    color: white;
}

@media screen and (max-width: 550px) {
    .topBar {
        top: 1.5rem;
    }

    .navIcon {
        margin-right: 5rem;     
    }
}

@media screen and (max-width: 700px) {
    .topBar {
        top: 2rem;
    }

    .navIcon {
        margin-right: 6rem;
    }
}

@media screen and (max-width: 850px) {
    .topBar {
        top: 2rem;
    }

    .navIcon {
        margin-right: 6rem;
    }
}