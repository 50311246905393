.swiitverse__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background:-moz-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4144A3', endColorstr='#8D3198', GradientType=0 );

    /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(141, 49, 152, 1) 0%, rgba(65, 68, 163, 1) 100%);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.swiitverse__cta-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.swiitverse__cta-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.swiitverse__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.swiitverse__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

.swiitverse__cta-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .swiitverse__cta {
        flex-direction: column;
    }

    .swiitverse__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .swiitverse__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .swiitverse__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .swiitverse__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}
