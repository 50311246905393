.swiitverse__MintWhiteList {
    display: flex;
    flex-direction: row;

 /*    background: rgb(141,49,152);
    background: radial-gradient(circle, rgba(141,49,152,1) 0%, rgba(65,84,163,1) 100%);
 */

  
    /* background: rgb(3,27,52);
    background: linear-gradient(180deg, rgba(3,27,52,1) 5%, rgba(65,84,163,1)40%, rgba(141,49,152,1) 75%); */
    
    /* background: linear-gradient(120deg, #FF00C7 0%, #51003F 100%), linear-gradient(120deg, #0030AD 0%, #00071A 100%), linear-gradient(180deg, #000346 0%, #FF0000 100%), linear-gradient(60deg, #0029FF 0%, #AA0014 100%), radial-gradient(100% 165% at 100% 100%, #FF00A8 0%, #00FF47 100%), radial-gradient(100% 150% at 0% 0%, #FFF500 0%, #51D500 100%);
    background-blend-mode: overlay, color-dodge, overlay, overlay, difference, normal;
 */

/*     background: linear-gradient(320.54deg, #00069F 0%, #120010 72.37%), linear-gradient(58.72deg, #69D200 0%, #970091 100%), linear-gradient(121.28deg, #8CFF18 0%, #6C0075 100%), linear-gradient(121.28deg, #8000FF 0%, #000000 100%), linear-gradient(180deg, #00FF19 0%, #24FF00 0.01%, #2400FF 100%), linear-gradient(52.23deg, #0500FF 0%, #FF0000 100%), linear-gradient(121.28deg, #32003A 0%, #FF4040 100%), radial-gradient(50% 72.12% at 50% 50%, #EB00FF 0%, #110055 100%);
background-blend-mode: screen, color-dodge, color-burn, screen, overlay, difference, color-dodge, normal; */


background: linear-gradient(115deg, rgb(211, 255, 215) 0%, rgb(0, 0, 0) 100%), radial-gradient(90% 100% at 50% 0%, rgb(200, 200, 200) 0%, rgb(22, 0, 45) 100%), radial-gradient(100% 100% at 80% 0%, rgb(250, 255, 0) 0%, rgb(36, 0, 0) 100%), radial-gradient(150% 210% at 100% 0%, rgb(112, 255, 0) 0%, rgb(20, 175, 125) 0%, rgb(0, 10, 255) 100%), radial-gradient(100% 100% at 100% 30%, rgb(255, 77, 0) 0%, rgba(0, 200, 255, 1) 100%), linear-gradient(60deg, rgb(255, 0, 0) 0%, rgb(120, 86, 255) 100%);
background-blend-mode: overlay, overlay, difference, difference, difference, normal;

background: linear-gradient(328.78deg, #030086 14.45%, #BD6177 84.36%), linear-gradient(301.28deg, #209B4A 0%, #7000FF 100%), radial-gradient(100% 138.56% at 100% 0%, #D50000 0%, #00FFE0 100%), radial-gradient(100% 148.07% at 0% 0%, #D50000 0%, #00FFFF 100%);
background-blend-mode: soft-light, overlay, difference, normal;

background: linear-gradient(180deg, #FFFFFF 0%, #000000 100%), linear-gradient(90deg, #FFFFFF 0%, #000000 100%), #FF00FF;
background-blend-mode: multiply, screen, normal;

background: linear-gradient(114.95deg, rgba(235, 0, 255, 0.5) 0%, rgba(0, 71, 255, 0) 34.35%), linear-gradient(180deg, #004B5B 0%, #FFA7A7 100%), linear-gradient(244.35deg, #FFB26A 0%, #3676B1 50.58%, #00A3FF 100%), linear-gradient(244.35deg, #FFFFFF 0%, #004A74 49.48%, #FF0000 100%), radial-gradient(100% 233.99% at 0% 100%, #B70000 0%, #AD00FF 100%), linear-gradient(307.27deg, #219D87 0.37%, #2650BA 50.19%, #2800C6 100%), radial-gradient(100% 140% at 100% 0%, #FF00C7 0%, #006C7A 49.48%, #760000 100%);
background-blend-mode: hard-light, overlay, overlay, overlay, difference, difference, normal;


}

.swiitverse__MintWhiteList h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    color: #fff;
    text-shadow: 0 0 14px rgb(222, 28, 138);
}

.swiitverse__MintWhiteList-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.swiitverse__MintWhiteList-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.swiitverse__MintWhiteList-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: white;

    margin-top: 1.5rem;
}

.swiitverse__MintWhiteList-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.swiitverse__MintWhiteList-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.swiitverse__MintWhiteList-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    
    border-radius: 25px;
}

.swiitver__MintWhiteList-content__inputMint {
    flex: 0.6;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}


.swiitverse__MintWhiteList-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.swiitverse__MintWhiteList-content__people img {
    width: 181.79px;
    height: 38px;
}

.swiitverse__MintWhiteList-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.swiitverse__MintWhiteList-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiitverse__MintWhiteList-image img {
    width: 100%;
    height: 100%;
}

.swiitver__MintWhiteList-buttonHowWallet {
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: left;
    margin-bottom: 2rem;
}

.buttonTuto {
    width: 100%;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #ede8e7;
    padding: 0.5rem 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
}

.gradient {
    width: 30vw;
    height: 15vw;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #9581F4, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    border-radius: 20px;
    box-shadow: 0px 0px 20px black;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.test {
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.test1 {
    border: 2px solid white;
    border-radius: 25px;
    width:fit-content;
    display: flex;
    padding: 0.5rem 0.5rem;
    margin-bottom: 2rem;
}

.test22 {
    font-weight: 400;
    font-size: 40px;
}







@media screen and (max-width: 1050px) {
    .swiitverse__MintWhiteList {
        flex-direction: column;
    }

    .swiitverse__MintWhiteList-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .swiitverse__MintWhiteList-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .swiitverse__MintWhiteList-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .swiitverse__MintWhiteList-content__input input,
    .swiitverse__MintWhiteList-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .swiitverse__MintWhiteList-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .swiitverse__MintWhiteList-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .swiitverse__MintWhiteList-content__input input,
    .swiitverse__MintWhiteList-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}

.mintAmountText{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    color: #fff;
    text-shadow: 0 0 14px rgb(222, 28, 138);
}

.containerInputMintWhiteList {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    
    width: 100%;
}
  
  .containerInputMintWhiteList p{
    font-family: var(--font-family);
    color: #fff;
    text-shadow: 0 0 14px rgb(222, 28, 138);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem;
    padding: 1rem;
  }
  
  .buttonNumberMintWL {
    flex: 0.6;
    width: 100%;
    max-width: fit-content;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 1 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 20px;
  }
  
  .buttonWLMint {
    flex: 0.6;
    width: 100%;
    max-width: 40%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #ede8e7;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-radius: 5px;
}



